import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';

@Injectable()
export class DownloadReportService {
  private url1 = this.globals.apiServerIP + '/api/getAppraisalReport';
  private url2 = this.globals.apiServerIP + '/api/getAppraisalOfLastCyclesReport';
  private url3 = this.globals.apiServerIP + '/api/getResourcingReport';
  private url4 = this.globals.apiServerIP + '/api/getProfitabilityReport';
  private url5 = this.globals.apiServerIP + '/api/getEstProfitabilitySOWReport';
  private url6 = this.globals.apiServerIP + '/api/getAppraisalTemplate';
  private url7 = this.globals.apiServerIP + '/api/getActiveSOWEstimatedProfitability';
  private url8 = this.globals.apiServerIP + '/api/getActiveSOWProjectList';
  private url9 = this.globals.apiServerIP + '/api/getActiveSOW';
  private url10 = this.globals.apiServerIP + '/api/getSowTrackingInfo';
  private url11 = this.globals.apiServerIP + '/getDailyUtilization';
  private url12 = this.globals.apiServerIP + '/api/getResourcingReportofLast15Days';



  constructor(
    private httpClient: HttpClient,
    private globals: Globals,

  ) { }

  getAppraisalReport(reqObj:any) {
    let params = new HttpParams();
    params = params.append('from', reqObj.from);
    params = params.append('to', reqObj.to);
    params = params.append('token', this.globals.getLoginUserToken());
    return this.httpClient.get(this.url1,{ params: params })
      .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );

  };

  getResourcingReport(reqObj:any) {
    let params = new HttpParams();
    params = params.append('from', reqObj.from);
    params = params.append('to', reqObj.to);

    return this.httpClient.get(this.url3,{ params: params })
    .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );
  };

  getProfitabilityReport(reqObj:any) {
    let params = new HttpParams();
    params = params.append('from', reqObj.from);
    params = params.append('to', reqObj.to);
    params = params.append('token', this.globals.getLoginUserToken());
    params = params.append('extraCostsToInclude', reqObj.extraCostsToInclude);
    return this.httpClient.get(this.url4,{ params: params })
    .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );
  };
  getEstProfitabilitySOWReport(reqObj:any) {
    let params = new HttpParams();
    params = params.append('from', reqObj.from);
    params = params.append('to', reqObj.to);
    params = params.append('year', reqObj.year);
    params = params.append('selection', reqObj.selection);
    params = params.append('token', this.globals.getLoginUserToken());

    return this.httpClient.get(this.url5,{ params: params })
    .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );
  };

  getAppraisalTemplate(reqObj:any) {
    let params = new HttpParams();

    params = params.append('filename', reqObj.filename);

    params = params.append('token', this.globals.getLoginUserToken());
    // console.log(params);
    return this.httpClient.get(this.url6,{ params: params })
    .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );
  };



  getReport(reqObj:any) {
    let params = new HttpParams();
    params = params.append('status', reqObj.status);
    params = params.append('from', reqObj.from);
    params = params.append('to', reqObj.to);
    params = params.append('token', this.globals.getLoginUserToken());
    return this.httpClient.get(this.url2,{ params: params })
    .pipe(
      map((response:any)=> {
        return response [0].data;
      }),
      catchError(this.handleError)
      );

  };

  getActiveSOWEstimatedProfitability() {
    let params = new HttpParams();

    /* Custom report for Sushant and Arvind. */
    /* params = params.append('type', 'Sushant'); */
     /* params = params.append('type', 'Arvind'); */

    params = params.append('token', this.globals.getLoginUserToken());
    return this.httpClient.get(this.url7,{ params: params })
    .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );
  };


  getActiveSOWProject() {
    let params = new HttpParams();

    /* Custom report for Sushant and Arvind. */
    /* params = params.append('type', 'Sushant'); */
    /* params = params.append('type', 'Arvind'); */

    params = params.append('token', this.globals.getLoginUserToken());
    return this.httpClient.get(this.url8,{ params: params })
    .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );
  };

  getActiveSOW() {
    let params = new HttpParams();

    /* Custom report for Sushant and Arvind. */
    /* params = params.append('type', 'Sushant'); */
    /* params = params.append('type', 'Arvind'); */

    params = params.append('token', this.globals.getLoginUserToken());
    return this.httpClient.get(this.url9,{ params: params })
    .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );
  };

  getSowTrackingReport(reqObj:any) {
    let params = new HttpParams();
    params = params.append('from', reqObj.from);
    params = params.append('to', reqObj.to);
    params = params.append('token', this.globals.getLoginUserToken());
    return this.httpClient.get(this.url10,{ params: params })
    .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );
  };


  getPendingDUT(queryparams:any) {
        // let params = new HttpParams();
        // params = params.append('tasks', taskdata);
        return this.httpClient.post(this.globals.apiServerIP + '/getDailyUtilizationForDownload' ,queryparams)
        .pipe(
        map((response:any)=> {
        return response;
      }),
      catchError(this.handleError)
      );

  };
  getProjectUtilization(queryparams: any) {
    return this.httpClient.post(
      this.globals.apiServerIP + '/getProjectUtilization/', queryparams
    )
    .pipe(
      map((response:any)=> {
      return response;
    }),
    catchError(this.handleError)
    )
  };
  getDesignationUtilization(queryparams: any) {
    return this.httpClient.post(
      this.globals.apiServerIP + '/getDesignationUtilization/', queryparams
    )
    .pipe(
      map((response:any)=> {
      return response;
    }),
    catchError(this.handleError)
    )
  };

  getResourcingReportofLast15Days(reqObj:any){
    let params = new HttpParams();
    params = params.append('resourcingIntervalDays', reqObj.resourcingIntervalDays);
    params = params.append('resourcingType', reqObj.resourcingType);
    params = params.append('resouring_Employee_Status', reqObj.resouring_Employee_Status);
    return this.httpClient.get(this.url12,{ params: params })
    .pipe(
      map((response:any)=> {
        return response[0].data;
      }),
      catchError(this.handleError)
      );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred while fetching the details.', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}
